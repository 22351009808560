import React from 'react'
import styled, { css } from 'styled-components'

import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { nextFrame } from '@/utils/async'

export interface TextAreaProps extends FormFieldContext {
	className?: string
	rows?: number
	value?: string
	autoFocus?: boolean
	onKeyDown?:
		| ((event: React.KeyboardEvent<HTMLTextAreaElement>) => void)
		| undefined
	textAreaHeight?: string
}

class TextArea extends React.PureComponent<TextAreaProps> {
	input = React.createRef<HTMLTextAreaElement>()

	async componentDidMount() {
		await nextFrame()

		if (this.input.current && this.props.autoFocus) {
			this?.input?.current?.setSelectionRange(
				this.input.current?.value.length,
				this.input.current?.value.length,
			)

			this.input.current.focus()
		}
	}

	onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (this.props.onChange) {
			this.props.onChange(e.target.value)
		}
	}

	onFocus = (e: React.FocusEvent) => {
		const { onFocus } = this.props

		this?.input?.current?.setSelectionRange(
			this.input.current?.value.length,
			this.input.current?.value.length,
		)

		this?.input?.current?.focus()

		if (onFocus) {
			onFocus(e)
		}
	}

	onBlur = (e: React.FocusEvent) => {
		const { onBlur } = this.props

		if (onBlur) {
			onBlur(e)
		}
	}

	render() {
		const { id, value, className, name, disabled, rows, isCompact, onKeyDown, textAreaHeight } =
			this.props

		return (
			<Container
				id={id}
				ref={this.input}
				name={name}
				className={className}
				disabled={disabled}
				onChange={this.onChange}
				$compact={!!isCompact}
				rows={rows}
				value={value || ''}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onKeyDown={onKeyDown}
				textAreaHeight={textAreaHeight}
			/>
		)
	}
}

const Container = styled.textarea<{ $compact: boolean; disabled?: boolean; textAreaHeight?:string }>`
	${(props) => css`
		resize: vertical;
		height:  ${props.textAreaHeight ? props.textAreaHeight : "auto"} ;
		white-space: pre-wrap;
		font-size: ${props.theme.input.fontSize};
		border-color: ${props.$compact
			? `${props.theme.colors.input.$compact?.horizontalBorder} ${props.theme.colors.input.$compact?.verticalBorder}`
			: props.theme.colors.input.border};
		border-radius: ${props.$compact ? 0 : props.theme.input.borderRadius};
		padding: ${props.theme.input.padding};
		:focus {
			border-color: ${props.theme.colors.primary.base};
			box-shadow: 0 0 3px ${props.theme.colors.primary.shadowColor};
		}
		:hover {
			box-shadow: ${props.disabled
				? 'none'
				: `0 0 3px ${props.theme.colors.primary.shadowColor}`};
		}
	`}
`

export default withFormField(TextArea)
