import { useAppContext, useAppStore, useTableColumns } from '@/hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { addStructureToRelease, getReleaseStructures } from '@/endpoints'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faPlus } from '@fortawesome/free-solid-svg-icons'
import { sortBy } from 'lodash'
import { DropZone, HeaderContainer, Info } from '../../styles'
import { DeploymentPackageStructureProps } from '../../types'
import { DpStructureTable } from './DpStructureTable'
import { useDpStructureTableColumns } from './useDpStructureTableColumns'
import { Select } from '@/components/UberForm'
import { getEnvSelectOptions, retrieveNodeData } from './utils'
import { Loader } from '@/components'
import isEmpty from 'lodash/isEmpty'

export const DeploymentPackageStructure = ({
	node,
	deploymentPackage,
	editMode,
	dpEnvironments,
}: DeploymentPackageStructureProps) => {
	const { t } = useAppContext()
	const nodeState = useAppStore((state) => state.node)

	const [envOption, setEnvOption] = useState<string>()

	useEffect(() => {
		if (dpEnvironments) {
			setEnvOption(getEnvSelectOptions(dpEnvironments)?.[0]?.value)
		}
	}, [dpEnvironments])

	const request = useApiRequest()

	const {
		data: objects,
		loading,
		invalidate,
	} = useApi(
		getReleaseStructures(
			node.id || (node as unknown as number),
			deploymentPackage.id!,
		),
	)

	const objectsSorted = useMemo(
		() => sortBy(objects?.content, (o) => o.structureId),
		[objects],
	)

	const transformedObjects = objectsSorted?.map((object) => {
		const envInfoData = object?.environmentInfo

		const buildSubRows = () => {
			if (isEmpty(envInfoData)) {
				return []
			}

			const { deploymentInfo, envCode } = envInfoData?.[0] || {}

			return (deploymentInfo || []).map((info) => ({
				...info,
				envCode,
			}))
		}

		if (!isEmpty(envInfoData)) {
			return {
				...object,
				subRows: buildSubRows(),
			}
		}

		return {
			...object,
		}
	})

	const reloadObjects = useCallback(() => invalidate(), [invalidate])

	const columns = useTableColumns({
		tableData: [objectsSorted ?? []],
		properties: useDpStructureTableColumns(envOption),
	})

	const onDrop = async (e: React.DragEvent) => {
		const validNodes = retrieveNodeData(e, objects, nodeState)

		await request(
			addStructureToRelease(
				node.id || (node as unknown as number),
				deploymentPackage.id!,
				[validNodes as number],
			),
		)

		invalidate()
	}

	const onDragOver = (e: React.DragEvent) => {
		e.preventDefault()
	}

	const onChange = (e: any) => {
		setEnvOption(e)
	}

	return (
		<section className="my-4">
			<HeaderContainer>
				<h1>{t('ELEMENTS_IN_RELEASE')}</h1>
			</HeaderContainer>

			<div className="flex items-center pt-3 pb-4 px-0">
				{editMode && (
					<div>
						<section className="flex content-end pr-2">
							<Info title={t('RELEASE_DRAG_DROP_HINT')}>
								<FontAwesomeIcon icon={faInfo} />
							</Info>
						</section>
						<DropZone onDrop={onDrop} onDragOver={onDragOver}>
							<FontAwesomeIcon icon={faPlus} style={{ marginRight: '4px' }} />
							{t('ADD_ELEMENT')}
						</DropZone>
					</div>
				)}

				<div className="ml-3">
					<label className="mb-3">Select an environment to expand:</label>
					<Select
						options={getEnvSelectOptions(dpEnvironments)}
						onChange={onChange}
						className="max-w-xl"
						customWidth="200px"
						value={envOption}
						clearable={false}
					/>
				</div>
			</div>

			{loading ? (
				<Loader loaded={!loading} $absolute />
			) : (
				<DpStructureTable
					deploymentPackage={deploymentPackage}
					sortedObjectsData={transformedObjects}
					editMode={editMode}
					node={node}
					reloadObjects={reloadObjects}
					columns={columns}
					hasLastRowEdit={false}
				/>
			)}
		</section>
	)
}
