import React, { useState } from 'react'
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '@/components/Button/Button'
import { Modal } from '@/components/Modal/Modal'
import { StructureObjectDto } from '@/endpoints/models'
import { revertHistory } from '@/store/modules/history/actions'
import { useTabContext } from '@/context/TabContext/TabContext'
import { useAppDispatch, useAppContext } from '@/hooks'
import { Loader } from '@/components/Loader/Loader'

type Props = {
	node: StructureObjectDto
	onConfirm: (node: StructureObjectDto) => Promise<void>
	onClose: () => void
	type: string
}

export const DpStatusModal = ({ node, onConfirm, onClose, type }: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const { onSaveError } = useTabContext()
	const [loading, setLoading] = useState(false)

	const handleConfirm = async () => {
		setLoading(true)

		try {
			await dispatch(revertHistory(node.id))
			await onConfirm(node)
		} catch (e) {
			onSaveError(e)
		}

		setLoading(false)
		onClose()
	}

	const getHeader = () => {
		if (type === 'CANCEL') {
			return t('CANCEL_EDIT_HEADER', [node.name])
		} else {
			return t('COMPLETE_EDIT_HEADER', [node.name])
		}
	}

	const getMessage = () => {
		if (type === 'CANCEL') {
			return t('CANCEL_EDIT_MESSAGE', [node.name])
		} else {
			return t('COMPLETE_EDIT_MESSAGE', [node.name])
		}
	}

	const customFooter = (
		close:
			| ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
			| undefined,
	) => (
		<>
			<Button
				icon={faTrash}
				schema="danger"
				onClick={handleConfirm}
				isLoading={loading}
			>
				{t('YES')}
			</Button>
			<Button schema="transparent" onClick={close} icon={faTimes}>
				{t('NO')}
			</Button>
		</>
	)

	return (
		<Modal
			open={true}
			header={getHeader}
			footer={customFooter}
			onClose={onClose}
		>
			<div>
				<Loader loaded={!loading} $absolute />
				<p>{getMessage()}</p>
			</div>
		</Modal>
	)
}
